<template>
  <div>
    <!-- Controllers -->
    <v-row
      no-gutters
      color="white"
      class="d-flex justify-space-between py-4 pr-6"
    >
      <!-- Navigation -->
      <div class="d-flex flex-row align-center pl-16 my-4">
        <v-icon
          color="primary"
          size="16"
        >
          arrow_back
        </v-icon>
        <a @click="$router.push(lastRoute.name ? lastRoute : { name: `competition-matches-filter` })">
          <span class="ml-1 font-weight-bold">{{ $t('activity.editActivity.navigateBack') }}</span>
          <span v-text="lastRoute.name ? lastRoute.meta.headerTitle() : $t(`competition.sections.cohortsMatches`)" />
        </a>
      </div>
    </v-row>
    <v-container class="px-6 px-md-10 pt-0 pb-6">
      <v-row class="my-0">
        <v-col cols="12" md="9" class="py-0">
          <edit-team-or-origin-match :match="currentMatch" :organization-id="organizationId" :project-id="projectId" />
          <edit-date-and-location :match="currentMatch" :organization-id="organizationId" :project-id="projectId" />
          <edit-match-score
            v-if="userCanEditScore && showEditMatchScore(currentMatch)"
            :match="currentMatch" :organization-id="organizationId" :project-id="projectId"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import matchesAndRoundsMixin from '@/modules/competition/matches/matchesAndRoundsMixin'

export default {
  name: 'EditMatch',
  components: {
    EditTeamOrOriginMatch: () => import('@/modules/competition/matches/EditTeamOrOriginMatch'),
    EditDateAndLocation: () => import('@/modules/competition/matches/EditDateAndLocation'),
    EditMatchScore: () => import('@/modules/competition/matches/EditMatchScore'),
  },
  mixins: [matchesAndRoundsMixin],
  beforeRouteEnter(to, from, next) { next(vm => { vm.lastRoute = from }) },
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    cohortId: { type: String, required: true },
    matchId: { type: String, required: true },
  },
  data() {
    return {
      lastRoute: {},
    }
  },
  computed: {
    ...mapGetters('project', ['isUserSubmanager']),
    userCanEditScore: ({ isUserSubmanager }) => isUserSubmanager,
    currentMatch: ({ matches, cohortId, matchId }) => matches.find(m => m.id === matchId && m.cohortId === cohortId),
  },
  methods: {
    showEditMatchScore(item) {
      const validParams = !this.project?.features?.hasMatchEvents && item.team0?.id && item.team1?.id
      if (validParams && ['notStarted', 'finished'].includes(item.dbStatus)) return true
      return false
    },
  },
}
</script>
