import { mapGetters } from 'vuex'
import i18n from '@/plugins/i18n'
import dateFilter from '@/utils/mixins/dateFilter'

export default {
  mixins: [dateFilter],
  computed: {
    ...mapGetters({ project: 'project/data', rawRounds: 'competition/rounds', rawMatches: 'competition/matches' }),
    ...mapGetters('competition', ['isRegularNba', 'groups', 'matchLocations', 'cohorts', 'teams']),
    ...mapGetters('project', ['hasMatchLocationsFeature']),
    rounds: ({ rawRounds, isRegularNba }) => rawRounds.map(round => ({
      ...round,
      name: round.stageId === 'rrobin'
        ? `${i18n.t('project.competition.headers.round')} ${round.index + 1}`
        : i18n.t(`project.competition.elimination.${isRegularNba ? 'nbaRounds' : 'rounds'}.${round.index}`),
      shortName: round.stageId === 'rrobin'
        ? `${i18n.t('project.competition.headers.shortRound')}${round.index + 1}`
        : isRegularNba
          ? i18n.t(`project.competition.elimination.nbaRounds.${round.index}`)
          : round.index === 0 ? i18n.t('project.competition.elimination.rounds.0') : `1/${2 ** round.index}`,
    })),
    matches: ({ rawMatches, getMatchTeam, getMatchStatus, getMatchName, hasMatchLocationsFeature, matchLocations, cohorts, project, formatDatetime }) => rawMatches
      .map(match => {
        const location = matchLocations.find(l => l.id === match.locationId)
        const team0 = getMatchTeam(match, 0)
        const team1 = getMatchTeam(match, 1)
        return {
          ...match,
          uniqueId: `${match.cohortId}--${match.id}`,
          cohort: cohorts.find(c => c.id === match.cohortId),
          name: getMatchName(match, false),
          shortName: getMatchName(match),
          team0,
          team1,
          team0Logo: team0 ? team0.logo : null,
          team0Name: team0 ? team0.name : this.$t('project.competition.TBD'),
          team1Logo: team1 ? team1.logo : null,
          team1Name: team1 ? team1.name : this.$t('project.competition.TBD'),
          dbStatus: match.status,
          status: getMatchStatus(match),
          locationName: hasMatchLocationsFeature
            ? location ? `${location?.name} · ${location?.fields?.find(f => f.id === match.fieldId).name}` : ''
            : match.location?.name ?? '-',
          score: [team0.score, team1.score].every(score => !isNaN(score)) ? `${team0.score} - ${team1.score}` : null,
          dateStr: match.date ? formatDatetime(match.date, project.timezone) : '-',
        }
      }),
  },
  methods: {
    getMatchName(match, short = true) {
      const group = this.groups.find(g => g.cohortId === match.cohortId && g.id === match.groupId)
      const isSingleGroup = this.groups.filter(g => g.cohortId === match.cohortId && g.stageId === group.stageId).length === 1
      const round = this.rounds.find(r => r.cohortId === match.cohortId && r.id === match.roundId)
      const fixtureIndex = +match.fixtureId.split('-').slice(-1)[0].slice(7)
      return short
        ? `${isSingleGroup ? '' : `${group.name} · `}${round.shortName} · ${fixtureIndex + 1}`
        : `${isSingleGroup ? '' : `${group.name} · `}${round.name} · ${i18n.t('common.match')} ${fixtureIndex + 1}`
    },
    getMatchStatus(match) {
      if (match.status === 'finished' && match.isResultProcessed) return 'finished'
      if (match.status === 'finished' && !match.isResultProcessed) return 'notSigned'
      if (match.status === 'running') return 'running'
      if (!match.date) return 'notScheduled'
      if (match.status === 'notStarted') return 'notStarted'
    },
    getMatchTeam(match, teamIndex) {
      const team = Object.entries(match.teamsById).map(([id, data]) => ({ id, ...data })).find(({ index }) => index === teamIndex)
      const teamOrigin = Object.entries(match.teamsByOrigin ?? {}).map(([_, data]) => data).find(({ index }) => index === teamIndex)
      const dbTeam = team ? this.teams.find(t => t.id === team.id && t.cohortId === match.cohortId) : null
      return {
        ...(team && { ...team }),
        ...(teamOrigin && { origin: teamOrigin }),
        name: dbTeam?.name ?? this.getTeamOriginName(teamOrigin, match.cohortId),
        logo: dbTeam?.logo ?? null,
      }
    },
    getTeamOriginName(origin, cohortId) {
      if (origin?.type === 'group') {
        const group = this.groups.find(g => g.id === origin.id && g.cohortId === cohortId)
        const groupName = group.conference ? i18n.t(`project.competition.nbaConferences.${group.conference}`) : group.name
        return `${origin.position + 1}º ${groupName}`
      } if (origin?.type === 'fixture') {
        const match = this.rawMatches.find(m => m.cohortId === cohortId && m.fixtureId === origin.id)
        return `${i18n.t(`project.competition.match.${origin.position}`)} · ${this.getMatchName(match)}`
      } return 'TBD'
    },
  },
}
